$(document).ready(function () {
    var parentOffset = $('[col-id="Name"]').offset()
    var autoCompleteOffset = $('auto-complete').offset()
    $('.addRowSmall').click(function () {
        autoCompleteOffset.top = parentOffset.top + "px";
        autoCompleteOffset.left = parentOffset.left + "px";
        alert('autoCompleteOffset.top = parentOffset.top + "px";');
    });

    // Godown Page Accordion
    $(document).on('click', 'button.toggle div', function () {
        $('button.toggle').removeClass('accordActive')
        $(this).parent().addClass('accordActive');
        $(this).children('.os-icon').toggleClass('os-icon-ui-22 os-icon-ui-23');
    })

    $(document).on('click', '.toggle', function (e) {
        e.preventDefault();
        var $this = $(this);
        if ($this.next().hasClass('show')) {
            $this.next().removeClass('show');
            $this.next().slideUp(350);
            $this.parent().find('.os-icon').removeClass('os-icon-ui-23').addClass('os-icon-ui-22');
        } else {
            $this.parent().parent().find('li .inner').removeClass('show');
            $this.parent().parent().find('li .inner').slideUp(350);
            $this.next().toggleClass('show');
            $this.next().slideToggle(350);
            $this.parent().siblings().find('.os-icon').removeClass('os-icon-ui-23').addClass('os-icon-ui-22');
        }
    });
    
  //$('.menu-toggler').click(function () {
  //  $('body').toggleClass('menu-minimized');
  //});
  //if ($(window).innerWidth() <= 1025) {
  //  $('body').addClass('menu-minimized');
  //}
  //$(window).on('resize', function () {
  //  if ($(window).innerWidth() <= 1025) {
  //    $('body').addClass('menu-minimized');
  //  }
  //  if ($(window).innerWidth() >= 1025) {
  //    $('body').removeClass('menu-minimized');
  //  }
  //  if ($(window).innerWidth() <= 767) {
  //    $('body').removeClass('menu-minimized');
  //  }
  //});
  //if ($(window).innerWidth() <= 767) {
  //  $('body').removeClass('menu-minimized');
  //}

  // Languages
  //$("select.cLang").change(function(){
  //    var value = $(this).children("option:selected").val();
  //   $('html').attr('dir',value)
  //});
  //$("select.cLang").change();

  // 
  $('.os-dropdown-trigger').mouseover(function () {
    $(this).addClass('over');
  });
  $('.os-dropdown-trigger').mouseleave(function () {
    $(this).removeClass('over');
  });

  // Mobile Menu
  $('.mobile-menu-trigger').click(function () {
    $('.menu-and-user').slideToggle();
  });

  //// Navigation Dropdowns
  //$('.has-sub-menu').click(function () {
  //  $(this).toggleClass('active');
  //  $(this).siblings().removeClass('active');
  //});

  // Collapsible Panels
  var cardHeader = $('.pipeline-header');
  $(cardHeader).click(function () {
    $(this).children('.os-dropdown-trigger').children('.os-icon').toggleClass('os-icon-ui-23 os-icon-ui-22');
  });

  // Table Add Row
  $('.addTargetBtn').click(function () {
    $('.wStripped tbody').append('<tr><td class="text-center">1</td><td><div class="input-group"><input type="text" class="form-control" placeholder="Sales Target"></div></td><td><div class="input-group"><input type="text" class="form-control" placeholder="Sales Commission"></div></td><td class="row-actions"><a class="danger closeTarget" href="#"><i class="os-icon os-icon-ui-15 fs20"></i></a></td></tr>');
    $('.closeTarget').click(function () {
      $(this).closest('tr').remove();
    });
  });
  $('.closeTarget').click(function () {
    $(this).closest('tr').remove();
  });

  // Temp Code
  //$('.autocomplete-container .input-container input').addClass('form-control');
});
